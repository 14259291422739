.banner {
  width: 100vw;
  background-color: #f3f3f3; /* You can change the color as needed */
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px; /* Adjust padding as needed */
}

.headerFile {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BeneLogo {
  position: absolute;
  top: 10px;
  left: 40px;
  height: 50px; /* Adjust the size as needed */
}

.header-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
  .welcome{
    position: absolute;
    top: 70px;
    left: 42.5%;
    font-size: 20px;
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    padding: 6px 6px; /* Padding */
    border-radius: 10px; /* Rounded corners */
    color: black; /* Text color */
  }

  .wrong-env{
    position: absolute;
    display: inline-block;
    right: 22%;
    top: 20px;
    font-weight: bold;
    text-align: center;
    margin: 0 auto;
    padding: 6px 6px; /* Padding */
    border-radius: 10px; /* Rounded corners */
    color: black; /* Text color */
  }

  .EnvSwitch{
    position: absolute;
    display: inline-block;
    right: 23.75%;
    top: 50px;
    font-weight: bold;
    text-align: center;
    margin: 0 auto;
    padding: 6px 6px; /* Padding */
    border-radius: 10px; /* Rounded corners */
    cursor: pointer;
    border: 2px solid black;
  }

  .EnvSwitch:hover {
    background-color: white;
    border: 2px solid rgb(85, 83, 83);
  }

  .env {
    position: absolute;
    left: 40%;
    display: inline-block;
    top: 10px;
    text-align: center;
    margin: 0 auto;
    padding: 6px 6px; /* Padding */
    border-radius: 10px; /* Rounded corners */
    border: 2px solid black;
  }

.date-header {
    position: absolute;
    font-size: 20px;
    padding: 6px 6px;
    border-radius: 10px;
    color: white;
    text-align: left;
    right: 20px;
    top: 0px;
    border: 2px solid black;
  }